import React from 'react';
import { PageHeaderStandard } from '../page-header/page-header';
import { TypeResuableCompetitorTable } from './types';
import linkResolver from '../../utils/linkResolver';
import RichTextRender from '../../utils/rich-text';
import clsx from 'classnames';

const makeCheckBoxComponent = (yes: string, no: string): React.FC<{ checked: boolean; }> => ({ checked }) => {
    const params = checked ? { height: '32px', width: '32px' } : { height: '32px', width: '36px' };
    return <img src={checked ? yes : no} style={params} />;
}

const ReusableCompetitorTable = ({ item, index }: { item: TypeResuableCompetitorTable, index: number }) => {
    const componentData = item.primary;
    const tableData = componentData.table.document.data;

    const { header_color = 'default', background = 'default', header  } = componentData;
    const CheckBox = makeCheckBoxComponent(tableData.checked_image.url, tableData.unchecked_image.url);
    return (
        <div className={`reusable-component competitor-table bg--${background} header--${header_color}`}>
            <div className="container">
                <div className="row justify-center items-center">
                    <div className={`col-12 d-flex flex-col`}>
                        <h2>{RichTextRender(header)}</h2>
                        <table className="d-none d-md-block">
                            <thead>
                                <tr>
                                    <th />
                                    <th>{tableData.amazee_label}</th>
                                    <th>{tableData.competitor_1_name}</th>
                                    <th>{tableData.competitor_2_name}</th>
                                    <th>{tableData.competitor_3_name}</th>
                                </tr>
                            </thead>
                            <tbody className={clsx({ striped: tableData.striped_rows })}>
                                {tableData.rows.map((row, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <span>{row.feature}</span>
                                            </td>
                                            <td className="checkbox"><CheckBox checked={row.amazee} /></td>
                                            <td className="checkbox"><CheckBox checked={row.competitor1} /></td>
                                            <td className="checkbox"><CheckBox checked={row.competitor2} /></td>
                                            <td className="checkbox"><CheckBox checked={row.competitor3} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className={clsx("d-block d-md-none table mobile", { striped: tableData.striped_rows })}>
                            {tableData.rows.map((row, index) => {
                                return (
                                <div className="px-2 py-3" key={index}>
                                    <div className="feature mb-4 font-bold">
                                        {row.feature}
                                    </div>
                                    <div className="feature-availability flex flex-wrap">
                                        <div className="platform-availability flex items-center w-1/2 flex-shrink-0">
                                            <div className="label">{tableData.amazee_label}</div> 
                                            <CheckBox checked={row.amazee} />
                                        </div>
                                        <div className="platform-availability flex items-center w-1/2 flex-shrink-0">
                                            <div className="label">{tableData.competitor_1_name}</div> 
                                            <CheckBox checked={row.competitor1} />
                                        </div>
                                        <div className="platform-availability flex items-center w-1/2 flex-shrink-0">
                                            <div className="label">{tableData.competitor_2_name}</div> 
                                            <CheckBox checked={row.competitor2} />
                                        </div>
                                        <div className="platform-availability flex items-center w-1/2 flex-shrink-0">
                                            <div className="label">{tableData.competitor_3_name}</div> 
                                            <CheckBox checked={row.competitor3} />
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </div>
                </div>
            </div>
        </div>
        </div >
    );
}

export { ReusableCompetitorTable };