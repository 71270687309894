import React from 'react';
import Linked from '../../utils/linkedItem';
import linkResolver from '../../utils/linkResolver';
import ReadMoreButton from './read-more-button';
import TwoColumn from './two-column';
import { TypeReusableImageLeftTextRight, convert_image_link } from './types';

import RichTextRender from '../../utils/rich-text';

const ImageLeftContentRight: React.FC<{ item: TypeReusableImageLeftTextRight }> = ({ item }) => {
    const { primary } = item;
    const { image, content, read_more_link_to, right_side_read_more_link_text, read_more_link_colors, open_read_more_new_tab, image_link, image_link_opens_in_new_tab } = primary;
    const img = <img src={image.url} alt={image.alt} />;
    const imageLinkTarget = image_link_opens_in_new_tab ? '_blank': '_self';
    const converted_link = convert_image_link(image_link);
    const imgWrapped = image_link ? <Linked target={imageLinkTarget} link_to={converted_link}>{img}</Linked> : img;

    return (
        <TwoColumn 
            className='image-and-content' 
            left={imgWrapped} 
            right={
                <React.Fragment>
                    {RichTextRender(content)}
                    {read_more_link_to && right_side_read_more_link_text && <ReadMoreButton read_more_link_to={read_more_link_to} text={right_side_read_more_link_text} colors={read_more_link_colors} openReadMoreNewTab={open_read_more_new_tab}/>}
                </React.Fragment>
            }
            bgClass={primary.background} 
            textClass={primary.text_color} 
            headerClass={primary.header_color} 
        />
    )
}

export default ImageLeftContentRight;