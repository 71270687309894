import React from "react";
import { TypeReusableFactAuthor } from "./types";
import classNames from "classnames";
import { Quote } from "../../components/quote";
import RichTextRender from "../../utils/rich-text";
import clsx from "classnames";

const ReusableTechnologyFactsAuthor: React.FC<{
  item: TypeReusableFactAuthor;
}> = ({ item }) => {
  const { items } = item;
  const { primary } = item;
  const {
    introduction,
    quote_image,
    quote_subtext,
    quote_text,
    title,
    bg_color,
    col_layout,
  } = primary;

  const bgColor = "bg-" + bg_color;
  const colLayout = col_layout == "3" ? 4 : col_layout == "4" ? 2 : 6;

  return (
    <section className={classNames("", bgColor)}>
      <div className="container">
        <div className="text-center col-10 mx-auto">
          {RichTextRender(title)}
          {RichTextRender(introduction)}
        </div>
        {items && (
          <div className={clsx("flex flex-wrap justify-center mt-20 mx-auto", col_layout == "3" ? "col-12" : "col-10")}>
            {items.map((item, index) => (
              <div className={clsx("text-center mb-4", "col-lg-" + colLayout)}>
                <img
                  className="fact_image object-contain mx-auto mb-6"
                  src={item.fact_image.url}
                  alt={item.fact_image.alt}
                />
                <div className="fact_text">
                  {RichTextRender(item.fact_text)}
                </div>
              </div>
            ))}
          </div>
        )}
        {quote_text.text && (
          <div className="col-10 mx-auto">
            <Quote
              quote_text={quote_text}
              quote_image={quote_image}
              quote_subcopy={quote_subtext}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ReusableTechnologyFactsAuthor;
