import classNames from 'classnames';
import React from 'react';
import linkResolver from '../../utils/linkResolver';
import { AmazeeIOButton } from '../button/button';
import TwoColumn from './two-column';
import { TypeResuableContentLeftContactFormRight } from './types';
// import { pixelDrop } from '../../utils/pixel-drop';

import RichTextRender from '../../utils/rich-text';
import Linked from '../../utils/linkedItem';
import {Asterisk} from "../Asterisk";


type ContactFormState = {
    firstName: string;
    lastName: string;
    firstNameError: boolean;
    lastNameError: boolean;
    email: string;
    emailError: boolean;
    company: string;
    companyError: boolean;
    helpWith: string;
    helpWithError: boolean;
    textMessage: string;
    textMessageError: boolean;

    foundError: boolean;
    pressedSubmit: boolean;
    submitted: boolean;
}


class ContactForm extends React.Component<{
    location: Location,
    pixelUrl: string,
}, ContactFormState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            helpWith: '0',
            textMessage: '',

            firstNameError: false,
            lastNameError: false,
            emailError: false,
            companyError: false,
            helpWithError: false,
            textMessageError: false,

            pressedSubmit: false,
            foundError: false,
            submitted: false,
        };

        this.updateInput = this.updateInput.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.getData = this.getData.bind(this);
        this.submit = this.submit.bind(this);
        this.getInputClasses = this.getInputClasses.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    resetForm() {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            helpWith: '',
            textMessage: '',

            firstNameError: false,
            lastNameError: false,
            emailError: false,
            companyError: false,
            helpWithError: false,
            textMessageError: false,
        });
    }

    updateInput(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        const target = event.target;
        if (!target) {
            return;
        }
        const value = target.value as string;
        const name = (target.name) as keyof ContactFormState;

        this.setState({ [name]: value });
    }

    getData() {
        return {
            page: this.props.location.href,
            ...this.state,
        }
    }

    getInputClasses(name: string): string {
        const errorKey = `${name}Error`;
        return classNames('form-control', {
            'invalid': this.state[errorKey],
            'grey': name === 'helpWith' && (this.state.helpWith === '0' || !this.state.helpWith),
        });
    }

    isValid(event?: React.FormEvent<any> | React.KeyboardEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): boolean {
        // If event exists, only test the correct field
        let limitTestTo = null;
        let foundError = false;
        if (event && event.target && (event.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement).name) {
            limitTestTo = event.target.name;
        }

        if (!limitTestTo || limitTestTo === 'email') {
            if (!this.state.email || !this.state.email.trim() || this.state.email.indexOf('@') === -1) {
                this.setState({ emailError: true });
                foundError = true;
            } else {
                this.setState({ emailError: false });
            }
        }

        if (!limitTestTo || limitTestTo === 'firstName') {
            if (!this.state.firstName || !this.state.firstName.trim()) {
                this.setState({ firstNameError: true });
                foundError = true;
            } else {
                this.setState({ firstNameError: false });
            }
        }
        this.setState({ foundError });
        return !foundError;
    }

    submit(e: React.FormEvent) {
        this.setState({ foundError: false, pressedSubmit: true });

        if (!this.isValid()) {
            e.preventDefault();
            return false;
        }

        fetch('/api/contact/', {
            method: 'POST',
            body: JSON.stringify(this.getData()),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((val) => {
            this.resetForm();
            this.setState({ submitted: true });
            // pixelDrop('reusable', 'body', this.props.pixelUrl);

            // setTimeout(() => this.setState({ submitted: false }), 10 * 1000);
            window.location.href = '/request-a-demo/thank-you';
        }).catch((err) => {
            console.error('Error!', err);
        });
    }

    render() {
        if (this.state.submitted) {
            return <h3 className="col-12 thanks text-xl">Got it. We’ll be in touch soon.</h3>
        }

        return (
            <form action="" className="mb-16" onSubmit={(e) => e.preventDefault()}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.state.firstNameError ? 'has-error' : ''}`}>
                                <label className="form-label">First Name<Asterisk/></label>
                                <input onBlur={this.isValid} onKeyUp={this.isValid} type="text" className={this.getInputClasses('firstName')} name="firstName" onChange={this.updateInput} value={this.state.firstName} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`form-group ${this.state.lastNameError ? 'has-error' : ''}`}>
                                <label className="form-label">Last Name</label>
                                <input onBlur={this.isValid} onKeyUp={this.isValid} type="text" className={this.getInputClasses('lastName')} name="lastName" onChange={this.updateInput} value={this.state.lastName} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`form-group ${this.state.emailError ? 'has-error' : ''}`}>
                                <label className="form-label">Email<Asterisk/></label>
                                <input onBlur={this.isValid} onKeyUp={this.isValid} type="text" className={this.getInputClasses('email')} name="email" onChange={this.updateInput} value={this.state.email} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`form-group ${this.state.companyError ? 'has-error' : ''}`}>
                                <label className="form-label">Company</label>
                                <input onBlur={this.isValid} onKeyUp={this.isValid} type="text" className={this.getInputClasses('company')} name="company" onChange={this.updateInput} value={this.state.company} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`form-group ${this.state.helpWithError ? 'has-error' : ''}`}>
                                <label className="form-label">Interested In</label>
                                <select onBlur={this.isValid} onKeyUp={this.isValid} className={this.getInputClasses('helpWith')} name="helpWith" value={this.state.helpWith} onChange={this.updateInput}>
                                    <option value="0" style={{ display: 'none' }}>&nbsp;</option>
                                    <option value="Dedicated">Dedicated Hosting</option>
                                    <option value="Cloud">Cloud Hosting</option>
                                    <option value="Something else">Something else</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.state.textMessageError ? 'has-error' : ''}`}>
                                <label className="form-label">Anything else you'd like to add?</label>
                                <textarea onBlur={this.isValid} onKeyUp={this.isValid} className={this.getInputClasses('textMessage')} name="textMessage" value={this.state.textMessage} onChange={this.updateInput}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 text-gray-600">
                            <small className="block">
                                We will use the personal data you are sharing with us solely for the purpose of responding to your query.
                            </small>
                            <small className="block mt-2">By clicking submit below, you consent to allow amazee.io to store and process the personal information submitted above to provide you the content requested. See more here: <Linked link_to={{document: {data: {url: '/privacy-policy', is_external: false}}}}>Privacy Policy</Linked></small>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.foundError && this.state.pressedSubmit && (
                            <div className="col-12 error">
                                <h6 className="error">There was an error submitting the form. Please check the information is correctly filled out in the following fields:</h6>
                                <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                                    {this.state.firstNameError && <li>First Name</li>}
                                    {this.state.emailError && <li>Email</li>}
                                    {this.state.companyError && <li>Company</li>}
                                    {this.state.helpWithError && <li>How can we help?</li>}
                                </ul>
                            </div>
                        )}
                        <div className="col-12">
                            <AmazeeIOButton onClick={this.submit} classes='inverted submit-button w-auto cursor-pointer d-inline-block px-4 font-medium py-2 text-sm'>
                                <a>Submit Form</a>
                            </AmazeeIOButton>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

const ContentLeftContactFormRight: React.FC<{ item: TypeResuableContentLeftContactFormRight }> = ({ item }) => {
    const { primary } = item;
    const { left_side, header_color, header_size, text_color, background, vertical_alig } = primary;
    const vAlign = vertical_alig === 'top' ? 'flex-start' : vertical_alig;

    const params = {};

    if (typeof window !== 'undefined' && window.location) {
        params.location = location;
    }
    return <TwoColumn
        className='content-and-form'
        left={RichTextRender(left_side)}
        right={
            <ContactForm {...params} />
        }
        bgClass={background}
        textClass={text_color}
        headerClass={header_color}
        valignColumns={vAlign}
    />
};

export default ContentLeftContactFormRight;
