import React from "react";
import { TypeReusableImageText, convert_image_link } from "./types";
import classNames from "classnames";
import RichTextRender from "../../utils/rich-text";

const ReusableImageText: React.FC<{
  item: TypeReusableImageText;
}> = ({ item }) => {
  const { primary } = item;
  const {
    cta_image,
    cta_image_position,
    cta_bg_color,
    cta_text,
    layout,
  } = primary;

  const bgColor = "bg-" + cta_bg_color;

  return (
    <div className={classNames("reusable-image-text py-16 lg:py-16", bgColor)}>
      <div className="container">
        <div className="col-12 col-lg-10">
          <div className="row">
            <div
              className={classNames(
                "block lg:flex container mx-auto justify-center"
              )}
            >
              {cta_image_position === "left" ? (
                <div
                  className={
                    layout === "50/50"
                      ? "w-1/2 half"
                      : layout === "75/25"
                      ? "w-1/4"
                      : "w-3/4 quater"
                  }
                >
                  <img
                    className="object-contain"
                    src={cta_image.url}
                    alt={cta_image.alt}
                  />
                </div>
              ) : null}
              {cta_text && (
                <div
                  className={classNames(
                    cta_image_position === "left" ? "lg:ml-8" : "lg:mr-8",
                    layout === "50/50" ? "col-lg-6" : ""
                  )}
                >
                  {RichTextRender(cta_text)}
                </div>
              )}
              {cta_image_position !== "left" ? (
                <div
                  className={
                    layout === "50/50"
                      ? "w-1/2 half"
                      : layout === "75/25"
                      ? "w-1/4 quarter"
                      : "w-3/4"
                  }
                >
                  <img
                    className="object-contain mx-auto"
                    src={cta_image.url}
                    alt={cta_image.alt}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableImageText;
