import React from 'react';
import ReactDOMServer from "react-dom/server";
import { TypeResuableRawHtml } from './types';
import { getFlexTextForAlign } from './content';

import RichTextRender from '../../utils/rich-text';

const ReusableRawHtml = ({ item }: { item: TypeResuableRawHtml, index: number }) => {
    const {
        primary
    } = item;
    const {
        text_color,
        background,
        html_content,
        text_align,
        margin
    } = primary;

    if (!html_content) {
        return null;
    }

    const renderedAsString = ReactDOMServer.renderToString(RichTextRender(html_content));

    if (!renderedAsString) {
        return null;
    }
    const htmlContent = renderedAsString.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>').replace(/mobile-and-tablet-only/gi, 'd-lg-none mobile-and-tablet-only').replace(/desktop-only/gi, 'desktop-only d-none d-lg-block').replace(/&quot;/gi, '"').replace(/&lsquo;/gi, "'")
    const flexText = getFlexTextForAlign(text_align);
    const textAlign = `text-${text_align}`;
    const parentClass = `reusable-component reusable-component--${margin ? 'extra-spacing' : 'no-spacing'}`

    return (
        <div className={`${parentClass} raw-html bg--${background} text--${text_color} ${textAlign}`}>
            <div className="container">
                <div className="row justify-center items-center">
                    <div className={`col-12 col-md-8 d-flex flex-col items-${flexText}`}> 
                        <div className={'max-w-3xl'} dangerouslySetInnerHTML={{__html: htmlContent}} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ReusableRawHtml };
