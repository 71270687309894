import React, { useEffect } from 'react';
import RichTextRender from '../../utils/rich-text';
import { SectionDivider } from '../section-divider/section-divider';
import { TypeResuableContent } from './types';

const getFlexTextForAlign = (align: 'center' | 'left' | 'right') => {
    switch(align) {
        case 'left':
            return 'start';
        case 'right':
            return 'end';
        default:
            return align;
    }
};

const ReusableContent = ({item, index}: {item: TypeResuableContent, index: number}) => {
    const {
        primary
    } = item;
    const {
        text_color,
        text_align,
        background,
        content,
        show_header_separator,
        extra_bottom_spacing,
        header_color,
        contentHeader,
        header,
        header_size,
    } = primary;

    const trueHeader = contentHeader || header; // Fucking graphql complains if I don't rename this field, but prismic previews return it without renames;
    const flexText = getFlexTextForAlign(text_align);
    const textAlign = `text-${text_align}`;
    const parentClass = `reusable-component reusable-component--${extra_bottom_spacing || extra_bottom_spacing === undefined ? 'extra-spacing' : 'normal-spacing'}`

    return (
        <div className={`${parentClass} content bg--${background} text--${text_color} header--${header_color} ${textAlign}`}>
            <div className="container">
                <div className="row justify-center items-center">
                    <div className={`col-12 col-md-8 d-flex flex-col items-${flexText}`}>
                        {trueHeader && (
                            <>
                            {(header_size !== "large" &&
                                <h2 className={`${header_size} reusable-component__header`}>{RichTextRender(trueHeader)}</h2>
                            )}

                            {(header_size == "large" &&
                                <h1 className={`${header_size} reusable-component__header mb-0`}>{RichTextRender(trueHeader)}</h1>
                            )}
                            {show_header_separator && <SectionDivider />}
                            </>
                        )}
                        <div className={`max-w-3xl content mt-3 text-${text_align} font-hairline`}>{RichTextRender(content)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ReusableContent, getFlexTextForAlign };
