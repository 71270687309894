import React from 'react';
import linkResolver from '../../utils/linkResolver';
import TwoColumn from './two-column';
import { TypeResuableContentLeftHubspotFormRight } from './types';
import HubspotForm from '../hubspot-form';

import RichTextRender from '../../utils/rich-text';


const ContentLeftHubspotFormRight: React.FC<{ item: TypeResuableContentLeftHubspotFormRight, index: number; }> = ({ item, index }) => {
    const { primary } = item;
    const { left_side, header_color, header_size, text_color, background, vertical_alig } = primary;
    const {portal_id, form_id} = primary;
    const vAlign = vertical_alig === 'top' ? 'flex-start' : vertical_alig;

    const params = {};
    
    return <TwoColumn
        className='content-and-form'
        left={RichTextRender(left_side)}
        right={
            <HubspotForm portalId={portal_id} formId={form_id} id={`reusable-form-hubspot-${index}`}/>
        }
        bgClass={background}
        textClass={text_color}
        headerClass={header_color}
        valignColumns={vAlign}
    />
};

export default ContentLeftHubspotFormRight;