import React from "react";
import { ReusableContent } from "./content";
import { ReusableRawHtml } from "./raw_html";
import ContentLeftContactFormRight from "./content-left-form-right";
import { ResuableHeader } from "./header";
import { ResuableIconGallery } from "./icon-gallery";
import ImageLeftContentRight from "./image-left-content-right";
import ImageRightContentLeft from "./image-right-content-left";
import ReusableImageText from "./image-and-text";
import ReusableTechnologyFactsAuthor from "./technology-facts-author";
import "./style.scss";
import { TypeAnyComponent, TypeResuableBody, TypeReusableType } from "./types";
import ContentLeftHubspotFormRight from "./content-left-hubspot-right";
import { ReusableCompetitorTable } from "./competitor-table";

const getComponentForType = (type: TypeReusableType): React.FC<any> | null => {
  if (type === "header") {
    return ResuableHeader;
  }
  if (type === "image_left___text_right") {
    return ImageLeftContentRight;
  }
  if (type === "image_right___text_left") {
    return ImageRightContentLeft;
  }
  if (type === "icon_gallery") {
    return ResuableIconGallery;
  }
  if (type === "content_left___contact_form_right") {
    return ContentLeftContactFormRight;
  }
  if (type === "content") {
    return ReusableContent;
  }
  if (type === "raw_html") {
    return ReusableRawHtml;
  }
  if (type === "content___hubspot") {
    return ContentLeftHubspotFormRight;
  }
  if (type === "competitor_table") {
    return ReusableCompetitorTable;
  }
  if (type === "image_and_text") {
    return ReusableImageText;
  }
  if (type==="technology_facts_with_author") {
    return ReusableTechnologyFactsAuthor;
  }
  return null;
};

const ResuableBody: React.FC<{ body: TypeResuableBody }> = ({ body }) => {
  return (
    <div className="reusable-body">
      {body &&
        body.length > 0 &&
        body.map((item: TypeAnyComponent, index: number) => {
          const ReusableComponent = getComponentForType(item.slice_type);

          if (!ReusableComponent) {
            console.error("Unimplemented Component", item.slice_type);
            return null;
          }
          return <ReusableComponent key={index} index={index} item={item} />;
        })}
    </div>
  );
};

export default ResuableBody;
