import classNames from 'classnames';
import React from 'react';
import Linked from '../../utils/linkedItem';
import { PrismicPageDefinition } from '../../utils/queries/types';
import { AmazeeIOButton } from '../button/button';

const ReadMoreButton: React.FC<{
    read_more_link_to: PrismicPageDefinition;
    text: string;
    colors: boolean;
    openReadMoreNewTab?: boolean;
}> = ({ read_more_link_to, text, colors, openReadMoreNewTab = false }) => {
    if (!read_more_link_to || !read_more_link_to) {
        return null;
    }

    const targ = openReadMoreNewTab ? '_blank': '_self';

    const classes = classNames('d-inline-block mt-4', {
        inverted: !colors,
    })
    return <Linked target={targ} link_to={read_more_link_to}><AmazeeIOButton classes={classes}>{text}</AmazeeIOButton></Linked>
}

export default ReadMoreButton;