import React from 'react';
import ReadMoreButton from './read-more-button';
import TwoColumn from './two-column';
import { TypeReusableImageRightTextLeft, convert_image_link } from './types';
import linkResolver from '../../utils/linkResolver';
import Linked from '../../utils/linkedItem';

import RichTextRender from '../../utils/rich-text';

const ImageRightContentLeft: React.FC<{ item: TypeReusableImageRightTextLeft }> = ({ item }) => {
    const { primary } = item;
    const { left_side_read_more_link_text, read_more_link_to, content, image, read_more_link_colors, open_read_more_new_tab, image_link, image_link_opens_in_new_tab } = primary;
    const img = <img src={image.url} alt={image.alt} />;
    const imageLinkTarget = image_link_opens_in_new_tab ? '_blank': '_self';
    const imgWrapped = image_link ? <Linked target={imageLinkTarget} link_to={convert_image_link(image_link)}>{img}</Linked> : img;

    return <TwoColumn
        className='image-and-content'
        reverseOnMobile={true}
        right={imgWrapped}
        left={
            <React.Fragment>
                {RichTextRender(content)}
                {read_more_link_to && left_side_read_more_link_text && <ReadMoreButton read_more_link_to={read_more_link_to} text={left_side_read_more_link_text} colors={read_more_link_colors} openReadMoreNewTab={open_read_more_new_tab}/>}
            </React.Fragment>
        }
        bgClass={item.primary.background}
        textClass={item.primary.text_color}
        headerClass={item.primary.header_color}
    />
};

export default ImageRightContentLeft;