import React from 'react';
import { PageHeaderStandard } from '../page-header/page-header';
import { TypeResuableHeader } from './types';
import linkResolver from '../../utils/linkResolver';
import RichTextRender from '../../utils/rich-text';

const {RichText} = require('prismic-reactjs');

const ResuableHeader = ({item, index}: {item: TypeResuableHeader, index: number}) => {
    const {
        primary
    } = item;

    return (
        <PageHeaderStandard header={primary.header} preHeader={primary.preHeader} subheader={RichTextRender(primary.subheader)}/>
    );
}

export { ResuableHeader };