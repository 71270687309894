import React from 'react';
import { TypeReusableIconGallery, TypeReusableIconGalleryItem, convert_image_link } from './types';
import linkResolver from '../../utils/linkResolver';
import Linked from '../../utils/linkedItem';

import RichTextRender from '../../utils/rich-text';

const ResuableIconGalleryItem: React.FC<{item: TypeReusableIconGalleryItem}> = ({item}) => {
    const {
        icon,
        title,
        details,
        image_link,
    } = item;

    const innerImage = <div className='img' style={{backgroundImage: `url('${icon.url}')`}}/>
    const imageLinkTo = convert_image_link(image_link);
    const imageRendered = image_link ? <Linked link_to={imageLinkTo}>{innerImage}</Linked> : innerImage;

    return (
        <div className="icon-gallery__icon col-12 col-md-4 d-flex items-center flex-col text-center mb-10">
            {icon && imageRendered}
            {title && <h5>{title}</h5>}
            {details && <div className='details'>{RichTextRender(details)}</div>}
        </div>
    );
}

const ResuableIconGallery = ({ item }: { item: TypeReusableIconGallery }) => {
    const {
        primary,
        items,
    } = item;
    const {
        header_color,
        header_size,
        image_sizing,
        text_color,
        background,
        header,
    } = primary;

    return (
        <div className={`reusable-component icon-gallery bg--${background} text--${text_color} header--${header_color}`}>
            <div className="container">
                <div className="row justify-center items-center">
                    <div className="col-12 col-md-8">
                        {header && (
                            <div className="row mb-4">
                                <div className="col-12 text-center">
                                    <h5  className={`${header_size} reusable-component__header`}>{header}</h5>
                                </div>
                            </div>
                        )}
                        <div className={`icon-gallery__wrapper row justify-center items-start sizing--${image_sizing}`}>
                            {items && items.map((item, index) => <ResuableIconGalleryItem key={index} item={item}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ResuableIconGallery };

