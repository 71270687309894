import React from 'react';

const TwoColumn: React.FC<{
    left: React.FC | JSX.Element;
    right: React.FC | JSX.Element;
    className?: string;
    bgClass?: string;
    textClass?: string;
    headerClass?: string;
    reverseOnMobile?: boolean;
    valignColumns?: 'center' | 'flex-start' | 'flex-end';
}> = ({ left, right, bgClass = 'default', reverseOnMobile = false, className = '', textClass = 'default', headerClass = 'default', valignColumns = 'center' }) => {

    const internals = reverseOnMobile ? (
        <React.Fragment>
            <div className="d-block d-md-none col-md-6">{right}</div>
            <div className="col-12 col-md-6">{left}</div>
            <div className="d-none d-md-block col-md-6">{right}</div>
        </React.Fragment>
    ) : (
            <React.Fragment>
                <div className="col-12 col-md-6">{left}</div>
                <div className="col-12 col-md-6">{right}</div>
            </React.Fragment>
        );

    return (
        <div className={`reusable-component bg--${bgClass} ${className} text--${textClass} header--${headerClass}`}>
            <div className="container">
                <div className="row justify-center">
                    <div className="col-12 col-md-8">
                        <div className={`row items-${valignColumns}`}>
                            {internals}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TwoColumn;